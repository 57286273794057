import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import './style.css';
import { Row, Col, Button } from 'react-bootstrap';
import TeamCards from './TeamCards';
import MatchupCards from './MatchupCards';
import Legend from './legend';
import UserHeader from '../UserHeader';
import NBACoverImg from './img/nbaLandingPageCoverMobile.png';
import { Event } from '../Tracking';
import PlayerCards from './PlayerCards';

function UpcomingGames(props) {
	const { schedules, teams, playersInfo, match, user, purchasePlan, dispatch, freeAccess, sources, options, assignCurrentSport, page } = props;
	let { sport } = useParams();

	const nflPage = sport?.toUpperCase() === 'NFL';
	const nbaPage = sport?.toUpperCase() === 'NBA' || page?.toUpperCase() === 'NBA';

	const nflScoreboard = options?.data?.nflScoreboard;
	let nflScoreboardData = {
		profit: 0,
		wins: 0,
		winsPercentage: 0
	};
	if (nflScoreboard && nflPage) {
		nflScoreboard.map((s) => {
			nflScoreboardData.profit = nflScoreboardData.profit + s.payout_amount_bet;
			if (s.win) {
				nflScoreboardData.wins = nflScoreboardData.wins + 1;
			}
			return nflScoreboardData;
		});
		nflScoreboardData.winsPercentage = ((nflScoreboardData.wins / nflScoreboard?.length) * 100).toFixed(1);
		nflScoreboardData.profit = Math.floor(nflScoreboardData.profit);
	}

	const [showSchedules, handleShowSchedules] = useState(true);
	const [showTeams, handleShowTeams] = useState(false);
	const [showPlayers, handleShowPlayers] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);

		assignCurrentSport();

		if (!schedules.data) {
			dispatch({ type: 'GET_SCHEDULES' });
		}
		if (match.match) {
			dispatch({ type: 'CLEAR_MATCH' });
		}
		const query = new URLSearchParams(window.location.search);

		if (query.get('teamView')?.toUpperCase() === 'TRUE') {
			handleShowTeams(true);
			handleShowSchedules(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toggleScheduleView = () => {
		handleShowTeams(false);
		handleShowSchedules(true);
		handleShowPlayers(false);
	};

	const toggleTeamView = () => {
		handleShowTeams(true);
		handleShowSchedules(false);
		handleShowPlayers(false);
	};

	const togglePlayerView = () => {
		handleShowTeams(false);
		handleShowSchedules(false);
		handleShowPlayers(true);
	};

	const { sportsID, sportsName } = user;
	const title = showTeams ? `Teams Dashboard` : `The Matchup Edge`;
	if (!schedules && !teams) return null;
	if (schedules.data && !schedules.data.schedules && teams.data && !teams.data.teamInfo && !schedules?.isLoading) {
		return (
			<React.Fragment>
				<div className='desktopUserheader'>{user && user.name && <UserHeader username={user.name} title={title} />}</div>

				<div className='mobileUserheader'>{user && user.name && <UserHeader username={user.name} title={title} />}</div>
				<div id='fullHeight'>
					<div id='loading' />
				</div>
			</React.Fragment>
		);
	}

	if (user.isLoading || purchasePlan.isLoading || schedules.isLoading || freeAccess?.isLoading) {
		return (
			<div id='fullHeight'>
				<div id='loading' />
			</div>
		);
	}

	if (purchasePlan.isLoaded && !purchasePlan.error) {
		window.location.reload();
	}

	const teamInfo = teams && teams.data && teams.data.teamInfo;
	const playerInfo = playersInfo?.playersInfo;
	const showSportsInfo = options?.data?.sportsInfo?.form_display;

	return (
		<div>
			{!nbaPage || (user?.login && nbaPage) ? (
				<>
					<h1 className='cardUpdatedText'>Upcoming Games</h1>
					<section className='dashboardLegend container-fluid'>
						{nflScoreboard?.length && nflPage ? (
							<div className='scoreboardSection'>
								<Row className='scoreboardRow'>
									<Col lg={6} className='scoreboardCol'>
										<div className='scoreboardDiv'>
											<h4>
												2022-2024 NFL Winning Percentage
											</h4>
											<h2 className='scoreboardValue'>
												68.5%
											</h2>
											<p className='scoreboardDisclaimer'>Breakeven is typically 52.4%</p>
										</div>
									</Col>
									<Col lg={6} className='scoreboardCol'>
										<div className='scoreboardDiv scoreboardPicksDiv generalLink'>
											<h4>Correct Picks</h4>
											<h2 className='scoreboardValue'>
												113 of 165
											</h2>
										</div>
									</Col>
								</Row>
							</div>
						) : null}
						{!user?.login && !nbaPage ? (
							<Row id='dashboardSampleGameBlueBlackRow' className='dashboardSampleGameBlueBlackRow'>
								<Col lg={12} className='dashboardSampleGameBlackCol'>
									<div className='introDiv'>
										<p className='introTrialHeader'>Get access to injury data for Professional Football, Basketball and Soccer matchups.
											<span className='d-block'>(when in season).</span>
										</p> 
										<Button
											className='signupButton'
											onClick={() => {
												dispatch({
													type: 'AUDIT',
													payload: { action: 'SIGNUP' }
												});
												Event(sources?.source, 'CLICK_SIGNUP', 'DASHBOARD_SIGNUP_BUTTON');
											}}
											href='/signup'
										>
											JOIN FOR FREE
										</Button>
									</div>
								</Col>
							</Row>
						) : null}
						<hr className='hrStyle' />
            				<Row className='dashboardLegendRow'>
							<Col lg={12}>
								<p className='howItWorksText'>
									The Inside Injuries Health Score helps you understand which teams, position groups or players are healthier than others, based on the frequency, severity of their injuries as well as other factors. This data will help you make better, more accurate decisions in wagering, setting fantasy sports lineups or any other outcome-related
									activities.
								</p>
							</Col>
							{showSportsInfo ? <Legend /> : null}
						</Row>
						<hr id='cardSection' className='hrStyle' />
					</section>
				</>
			) : null}

			{!user?.login && nbaPage ? (
				<>
					<div className='landingPageCoverDiv'>
						<div className='landingPageCoverHeaderDiv'>
							<h1 className='landingPageCoverHeader'>Your Destination for NBA Injury Insights</h1>
							<p className='landingPageCoverText'>The missing piece to your DFS strategy.</p>
						</div>
						<img src={NBACoverImg} alt='NBA Player' className='landingPageCoverImg' />
						<div className='landingPageCoverImgDiv'>
							<div className='landingPageCoverInsightDiv'>
								<p className='landingPageCoverInsightPart'>SHOULDER</p>
								<div className='landingPageCoverInsightSmallCircle' />
								<div className='landingPageCoverInsightLine' />
								<div className='landingPageCoverInsightBigCircle' />
							</div>
							<div className='landingPageCoverSignupDiv'>
								<h3 className='landingPageCoverSignupHeader'>
									Pinpoint which players
									<br />
									are set to shine in your
									<br />
									lineup.
								</h3>
								<Button
									className='signupButton homeCoverSignupButton'
									onClick={() => {
										dispatch({
											type: 'AUDIT',
											payload: { action: 'SIGNUP' }
										});
										Event(sources?.source, 'CLICK_SIGNUP', 'DASHBOARD_SIGNUP_BUTTON');
									}}
									href='/signup'
								>
									JOIN FOR FREE
								</Button>
							</div>
						</div>
					</div>
				</>
			) : null}

			<div className='mobileUserheader'>{user && user.name && <UserHeader username={user.name} title={title} />}</div>

			<section className='cardSection container-fluid'>
				{showSportsInfo ? (
					<Row>
						<Col lg={12}>
							<div className='cardTypeDiv'>
								<Button
									className={`cardTypeButton ${showSchedules ? 'cardTypeButtonActive' : ''}`}
									active={showSchedules}
									onClick={() => {
										Event(sources?.source, 'VIEW_SCHEDULES', 'VIEW_SCHEDULES_BUTTON');
										!showSchedules && toggleScheduleView();
										dispatch({
											type: 'AUDIT',
											payload: { action: 'VIEW_SCHEDULES' }
										});
									}}
								>
									Upcoming Games
								</Button>
								<Button
									className={`cardTypeButton ${showTeams ? 'cardTypeButtonActive' : ''}`}
									active={showTeams}
									onClick={() => {
										Event(sources?.source, 'VIEW_TEAMS', 'VIEW_TEAMS_BUTTON');
										!showTeams && toggleTeamView();
										dispatch({
											type: 'AUDIT',
											payload: { action: 'VIEW_TEAMS' }
										});
									}}
								>
									Teams
								</Button>
								<Button
									className={`cardTypeButton ${showPlayers ? 'cardTypeButtonActive' : ''}`}
									active={showPlayers}
									onClick={() => {
										Event(sources?.source, 'VIEW_PLAYERS', 'VIEW_PLAYERS_BUTTON');
										!showPlayers && togglePlayerView();
										dispatch({
											type: 'AUDIT',
											payload: { action: 'VIEW_PLAYERS' }
										});
									}}
								>
									Players
								</Button>
							</div>
						</Col>
					</Row>
				) : null}
				<Row id='cardRow' className='cardRow' style={{ paddingTop: `${showTeams ? '45px' : ''}` }}>
					{showSchedules && (
						<>
							{nbaPage ? (
								<>
									<Col lg={4}>
										<h1 className='matchupCardsHeader'>Schedules</h1>
									</Col>
									<Col lg={8}></Col>
								</>
							) : null}

							<MatchupCards match={match} teams={teamInfo} sportsID={sportsID} sportsName={sportsName} nflPage={nflPage} nbaPage={nbaPage} />
						</>
					)}
					{showTeams && <TeamCards teams={teamInfo} sportsID={sportsID} sportsName={sportsName} nbaPage={nbaPage}/>}
					{showPlayers && <PlayerCards sportsName={sportsName} players={playerInfo} nbaPage={nbaPage}/>}
				</Row>
			</section>
		</div>
	);
}

const mapStateToProps = (state) => ({
	isLoading: state.isLoading,
	isLoaded: state.isLoaded,
	freeAccess: state.freeAccess,
	options: state.options,
	schedules: state.schedules,
	teams: state.teams,
	playersInfo: state.playersInfo,
	match: state.match,
	user: state.user,
	purchasePlan: state.purchasePlan,
	sources: state.sources,
	prices: state.prices
});

export default connect(mapStateToProps)(UpcomingGames);
